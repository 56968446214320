

const initialState = {};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case "SNACKBAR_SUCCESS":
            return {
                ...state,
                successSnackbarOpen: true,
                successSnackbarMessage: action.message
            };
        case "SNACKBAR_ERROR":
            return {
                ...state,
                errorSnackbarOpen: true,
                errorSnackbarMessage: action.message
            };
        case "SNACKBAR_CLEAR":
            return {
                ...state,
                successSnackbarOpen: false,
                errorSnackbarOpen: false,
                infoSnackbarOpen: false
            };

        default:
            return state;
    }
};

