export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";

export const UPDATE_SUCCESS = "успешно обновлено";
export const UPDATE_FAIL = "не смогли изменить";
export const DELETE_SUCCESS = "Успешно удалено";
export const DELETE_FAIL = "Не удалось удалить";
export const GET_SUCCESS = "DELETE_SUCCESS";
export const ASSIGN_SUCCESS = "ASSIGN_SUCCESS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const ASSIGN_FAIL = "ASSIGN_FAIL";
export const GET_FAIL = "ASSIGN_FAIL";
export const UPLOAD_FAIL = "UPLOAD_FAIL";
