import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import snack from  "./uiReducer";
import menu from  "./menu";

export default combineReducers({
    auth,
    message,
    snack,
    menu,
});
