
import React, { Component, Suspense, lazy } from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
// import Es_front from "./es_front";
import { HashRouter } from "react-router-dom";

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import Preloader from './preloader/Preloader';

const container = document.getElementById("root");
const root = createRoot(container);


const Es_front = lazy(() => import('./es_front'));

root.render(
    <Suspense fallback={<Preloader/>}>
        <Provider store={store}>
            <ThemeProvider>
                {/*<BrowserRouter>*/}
                    <HashRouter>
                      <Es_front />
                    </HashRouter>
                {/*</BrowserRouter>*/}
            </ThemeProvider>
        </Provider>
    </Suspense>
);
