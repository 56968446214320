import { OPEN_MENU, CLOSE_MENU } from "../actions/types";

const initialState = {menu: null};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case OPEN_MENU:
            return { menu: payload };

        case CLOSE_MENU :
            return { menu: null};

        default:
            return state;
    }
}
